import React, { useState } from 'react';
import cn from 'classnames';

import Logo from '../Logo/Logo';

const Header = () => {
  const [isActive, setActive] = useState(false);

  const burgerHandler = () => setActive(!isActive);

  const hamburgerStyle = cn({
    'inner-header__hamburger hamburger': true,
    active: isActive,
  });

  const menuStyle = cn({
    'inner-header__menu': true,
    open: isActive,
  });

  return (
    <header className="header">
      <div className="header__inner inner-header">
        <nav className="inner-header__nav">
          <div onClick={burgerHandler} className={hamburgerStyle}>
            <span></span>
            <span className="inner-header__hamburger--span"></span>
            <span></span>
          </div>
          <Logo />
          <div className={menuStyle}>
            <ul className="inner-header__list">
              <li>
                <a href="https://dose.daybreaker.com/browse?utm_medium=web&utm_source=dose-about&utm_campaign=nav_browse">
                  BROWSE
                </a>
              </li>
              <li>
                <a href="https://wearedose.daybreaker.com/">ABOUT</a>
              </li>
              <li>
                <a href="http://happinessblueprint.daybreaker.com/">
                  HAPPINESS BLUEPRINT
                </a>
              </li>
              <li>
                <a href="https://wearedose.daybreaker.com/#thescience">
                  THE SCIENCE
                </a>
              </li>
            </ul>
            <div className="inner-header__login">
              <a
                href="https://dose.daybreaker.com/checkout/subscribe/purchase?utm_medium=web&utm_source=dose-about&utm_campaign=nav-free-trial"
                className="inner-header__trial"
              >
                START FREE TRIAL
              </a>
              <a
                href="https://dose.daybreaker.com/login?utm_medium=web&utm_source=dose-about&utm_campaign=nav_sign-in"
                className="inner-header__sign"
              >
                SIGN IN
              </a>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
