import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FaqSection from './blocks/FaqSection';
import WelcomeSection from './blocks/WelcomeSection';
import { loadQuestions } from '../../actions/app';

const Main = () => {
  const dispatch = useDispatch();
  const questions = useSelector(({ app: { questionsList } }) => questionsList);

  useEffect(() => {
    loadQuestions(dispatch);
  }, [dispatch]);

  return (
    <main className="main">
      <WelcomeSection />
      {questions && <FaqSection questions={questions} />}
    </main>
  );
};

export default Main;
