import {
  SET_LOADING,
  SET_FAILED_REQUEST,
  SET_QEUSTIONS_LIST,
} from '../actions/app';

const initialState = {
  questionsList: null,
  isLoading: false,
  errorMessage: '',
};

const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case SET_QEUSTIONS_LIST:
      return {
        ...state,
        questionsList: payload,
      };

    case SET_FAILED_REQUEST:
      return {
        ...state,
        errorMessage: payload,
      };

    default:
      return state;
  }
};

export default appReducer;
