import React from 'react';
import Header from '../components/Header/Header';
import Main from '../components/Main/Main';

const FAQ = () => {
  return (
    <div className="wrapper">
      <Header />
      <Main />
    </div>
  );
};

export default FAQ;
