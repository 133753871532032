import React from 'react';

const WelcomeSection = () => {
  return (
    <section className="welcome">
      <div className="welcome__img">
        <img src="img/header.png" alt="Smiling girl with flower" />
        <video
          id="header-video"
          className="videoPlay"
          autoPlay
          muted
          loop
          playsInline
          src="video/header.mp4"
        />
      </div>
      <div className="welcome__inner --container">
        <div className="welcome__text">
          <h1 className="welcome__title">FAQ</h1>
          <h3 className="welcome__subtitle white-subtitle">
            Answers to your questions.
          </h3>
        </div>
      </div>
    </section>
  );
};

export default WelcomeSection;
