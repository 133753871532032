import { axios } from '../app/axiosConfig';

export const SET_LOADING = 'SET_LOADING';
export const SET_FAILED_REQUEST = 'SET_FAILED_REQUEST';
export const SET_QEUSTIONS_LIST = 'SET_QEUSTIONS_LIST';

export const setLoading = (value) => ({
  type: SET_LOADING,
  payload: value,
});

export const setFailedRequest = (status) => ({
  type: SET_FAILED_REQUEST,
  payload: status,
});

export const setQuestionsList = (questions) => ({
  type: SET_QEUSTIONS_LIST,
  payload: questions,
});

export const loadQuestions = async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await axios.get('/api/faq/?format=json');
    dispatch(setQuestionsList(data));
  } catch {
  } finally {
    dispatch(setLoading(false));
  }
};
