import React from 'react';
import { ReactComponent as Logotype } from '../../img/logo.svg';

const Logo = () => {
  return (
    <a
      href="https://dose.daybreaker.com?utm_medium=web&utm_source=dose-about&utm_campaign=nav_logo"
      className="inner-header__logo"
    >
      <Logotype />
    </a>
  );
};

export default Logo;
