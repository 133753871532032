import React, { useState } from 'react';
import cn from 'classnames';

const Question = ({ question, answer }) => {
  const [isActive, setActive] = useState(false);

  const handleActive = () => setActive(!isActive);

  const titleStyle = cn({
    'block-faq__title': true,
    active: isActive,
  });

  const textStyle = cn({
    'block-faq__text': true,
    active: isActive,
  });

  const answerHtml = (answerText) => {
    return { __html: `${answerText}` };
  };

  return (
    <div className="faq__block block-faq" onClick={handleActive}>
      <div className={titleStyle}>{question}</div>
      <div className={textStyle} dangerouslySetInnerHTML={answerHtml(answer)} />
    </div>
  );
};

export default Question;
