import React from 'react';
import Question from './Question';

const FaqSection = ({ questions }) => {
  return (
    <section className="faq">
      <div className="faq__inner --container">
        {questions.map(({ id, question, answer }) => (
          <Question key={id} question={question} answer={answer} />
        ))}
      </div>
    </section>
  );
};

export default FaqSection;
